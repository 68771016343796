export const environment = {
  production: true,
  apiUrl: 'https://api.dev-bo.grow-sme-week.eu',
  cognito: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_0YmeMuXmb',
    userPoolClientId: 'tsil58jsahsu5to87u49osbj4',
    userPoolDomain: 'dev-grow-sme-week',
    redirectSignOut: 'https://ecas.acceptance.ec.europa.eu/cas/logout',
  },
  cookieStorage: {
    secure: true,
  },
  frontofficeUrl: 'https://dev.sme-week.ec.europa.eu/home/view/',
};
